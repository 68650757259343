jQuery(document).ready(function ($) {
    $('#mobile-menu-button').click(function() {
        $(this).toggleClass('active');
        $('#site-navigation').toggleClass('active');
    });
    $('#site-navigation .menu-item-has-children, #top-menu .menu-item-has-children').click(function (e) {
        if ($(window).width() < 1024) {
            var smenu = $(this).find('.sub-menu');
            smenu.toggleClass('active');
        }
    });
    $('#site-navigation > div > .menu > .menu-item-has-children > a').click(function (e) {
        if ($(window).width() < 1024) {
            e.preventDefault();
        }
    })
});
